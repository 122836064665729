import 'ol/ol.css';
import 'ol-layerswitcher/src/ol-layerswitcher.css';
import {Map, View} from 'ol';
import LayerSwitcher from 'ol-layerswitcher';
import LayerGroup from 'ol/layer/Group';
import {Tile, VectorTile as VectorTileLayer, Vector as VectorLayer} from 'ol/layer';
import TileLayer from 'ol/layer/Tile';
import {transform} from 'ol/proj';
import VectorTileSource from 'ol/source/VectorTile';
import {Vector as VectorSource} from 'ol/source';
import {Circle as CircleStyle, Fill, Stroke, Style} from 'ol/style';
import MVT from 'ol/format/MVT';
import XYZ from 'ol/source/XYZ';
import BingMaps from 'ol/source/BingMaps';
import Icon from 'ol/style/Icon';
import {WFS, GeoJSON, WKT} from 'ol/format';
import {equalTo, and} from 'ol/format/filter.js';
import {Or} from 'ol/format/filter/Or.js';
import gp_frame_selected from './assets/gp_frame_selected.svg'
import Feature from "ol/Feature";
import Tabulator from "tabulator-tables";

const mModal = $('#mapModal');
const mModalTitle = $('#modalTitle');
const mModalBody = $('#mapModalBody');
const mModalTable = $('#assignTable');

export function degrees_to_radians (degrees) {
    let pi = Math.PI;
    return degrees * (pi/180);
}


export function loadMap(geopath_id) {
    const view = new View({
        center: transform([-95, 40], 'EPSG:4326', 'EPSG:3857'),
        zoom: 4.5,
        maxZoom: 19,
        projection: 'EPSG:3857'
    });
    const bing_img_base = new TileLayer({
        title: 'Aerial(Bing)',
        type: 'base',
        visible: false,
        source: new BingMaps({
            key: process.env.bing_api_key,
            imagerySet: 'AerialWithLabels',
            maxZoom: 19
        })

    });
    const esri_st_base = new Tile({
        title: 'Streets Basemap',
        type: 'base',
        visible: true,
        source: new XYZ({
            attributions: 'Tiles © <a href="https://services.arcgisonline.com/ArcGIS/' +
                'rest/services/World_Street_Map/MapServer">ArcGIS</a>',
            url: 'https://server.arcgisonline.com/ArcGIS/rest/services/' +
                'World_Street_Map/MapServer/tile/{z}/{y}/{x}'
        })
    });
    const pointSource = new VectorSource({});
    const pointLayer = new VectorLayer({
        source: pointSource
    });
    const assignmentsource = new VectorSource({});
    const assignmentlayer = new VectorLayer({
        source: assignmentsource,
    });
    const map = new Map({
        target: 'map',
        layers: [
            new LayerGroup({
                'title': 'Base maps',
                layers: [
                    esri_st_base,
                    bing_img_base
                ]
            }),
            new LayerGroup({
                title: 'Overlays',
                layers: [
                    pointLayer,
                    assignmentlayer
                ]
            }),
        ],
        view: view
    });
    const layerSwitcher = new LayerSwitcher();
    let assignmentPromises = [];
    let assignmentArray =[];
    let hereAssignmentList = [];

    map.addControl(layerSwitcher);
    function returner () {
        let featureprop = assignmentlayer.getProperties();
        let featureprops = featureprop["source"]["featuresRtree_"]["items_"];
        $.each(featureprops, function (index,value) {
            let id = value["value"]["id_"];
            let feature = assignmentsource.getFeatureById(id);
            feature.setStyle(new Style({
                stroke: new Stroke({
                    color: [0, 131, 154, 1],
                    width: 12
                })
            }));
        });
    }
    function flashStreet (segment_reg){
        let seg = segment_reg;
        let featureprop = assignmentlayer.getProperties();
        let featureprops = featureprop["source"]["featuresRtree_"]["items_"];
        $.each(featureprops, function (index,value) {
            if (value["value"]["values_"]["segment_reg"] === seg) {
                let id = value["value"]["id_"];
                let feature = assignmentsource.getFeatureById(id);
                feature.setStyle(
                    new Style({
                        stroke: new Stroke({
                            color: 'red',
                            width: 25
                        })
                    })
                );
            }
        });
        setTimeout(returner,3000)
    }
    function loadAssignmentTable() {
        new Tabulator("#assignTable", {
            selectable: 1,
            data: assignmentArray,
            height: '30vh',
            layout:"fitColumns",
            columns:[
                {
                    title: "Assignment ID",
                    field: "assignment_id",
                },
                {
                    title: "Segment ID",
                    field: "segment_reg",
                    visible: false
                },
                {
                    title: "Street",
                    field: "streetText",
                },
                {
                    title: "Traffic Type",
                    field: "mode",
                },
                {
                    title: "Read Type",
                    field: "readText",
                },
                ],
            rowClick: function (e, row,) {
                let selectedRowSegment = row.getData().segment_reg;
                flashStreet(selectedRowSegment)
            },
        })
    }
    function showassignments(frame_id) {
        //clear any existing layer assignments
        assignmentsource.clear();
        assignmentArray =[]
        hereAssignmentList =[]
        //set up request to intermx api
        let formData = {
            "geopath_id": frame_id
        };
        let settings = {
            "async": true,
            "crossDomain": true,
            "url": process.env.appurl + 'api/getAssignments',
            "method": "GET",
            "headers": {
                "Content-Type": "application/x-www-form-urlencoded/",
                "Authorization": "Bearer " + localStorage.getItem('access_token')
            },
            "data": formData
        };
        //get data from intermx api via express api and break apart into legacy and new
        $.ajax(settings).done(async function (response) {
            $.each(response, function (index, values) {
                $.each(values, function (index, value) {
                    // console.log(value)
                    if (value["assignment_type"]["id"] === 2) {
                        // console.log(value)
                        let segval = String(value["segment_id"])
                        let segdir = segval.split("_", 2);
                        let segment_id = segdir[0] + "_" + segdir[1];
                        let newVal = {
                            assignment_type_id: value["assignment_type"]["id"],
                            assignment_id: value["id"],
                            segment_id: segment_id,
                            segment_reg: value['segment_id'],
                            mode: value["mode"],
                            center_read: value['center'],
                            perpendicular: value['perpendicular'],
                            right_hand: value['right_hand'],
                            distance_from_audience: value['distance_from_audience'],
                            assignment_date: value['assignment_date']
                        };
                        hereAssignmentList.push(newVal);
                    } else if (value["assignment_type"]["id"] === 3) {
                        let segval = String(value["segment_id"])
                        let segdir = segval.split("_", 2);
                        let segment_id = segdir[0] + "_" + segdir[1];
                        let newVal = {
                            assignment_type_id: value["assignment_type"]["id"],
                            assignment_id: value["id"],
                            segment_id: segment_id,
                            segment_reg: value['segment_id'],
                            mode: value["mode"],
                            center_read: value['center'],
                            perpendicular: value['perpendicular'],
                            right_hand: value['right_hand'],
                            distance_from_audience: value['distance_from_audience'],
                            assignment_date: value['assignment_date']
                        };
                        hereAssignmentList.push(newVal)
                    }
                })
            });
            if(hereAssignmentList.length === 0){
                mModalTable.html('<h5>No Active New Assignments</h5>');
                assignmentArray = []
            }
            else {
                $.each(hereAssignmentList, function (index, value) {
                    // console.log(value)
                    assignmentPromises.push(new Promise(function (resolve) {
                        const segment_reg = value['segment_reg'];
                        const assignmentid = value['assignment_id'];
                        //prepapre geoserver request
                        const segfeatureRequest = new WFS().writeGetFeature({
                            srsName: 'EPSG:3857',
                            featurePrefix: 'geopath',
                            featureTypes: ['sl_2016r3_new_adjusted'],
                            outputFormat: 'application/json',
                            filter: equalTo('segment_reg', segment_reg)
                        });
                        //send geoserver request
                        let settings = {
                            "url": process.env.appurl + "api/getSegs",
                            "method": "post",
                            "content-type": "application/x-www-form-urlencoded",
                            "headers": {
                                "Authorization": "Bearer " + localStorage.getItem('access_token'),
                                "Geopath-User-Service-Account": localStorage.getItem('userEmail')
                            },
                            "data": {"obj":new XMLSerializer().serializeToString(segfeatureRequest)}
                        };
                        $.ajax(settings).done(function (response) {
                            // console.log(response)
                            let geojsonfeatures = new GeoJSON().readFeatures(response);
                            let properties = response['features'][0]['properties'];
                            let modeText;
                            if (value["mode"] === 'P') {
                                modeText = 'Pedestrian Traffic Only'
                            } else {
                                modeText = 'Both Pedestrian and Vehicular Traffic'
                            }
                            let readText;
                            if (value['right_hand'] === true) {
                                if (value['center_read'] === true) {
                                    readText = "Right/Center Read"
                                } else if (value['perpendicular'] === true) {
                                    readText = 'Right/Perpendicular Read'
                                } else {
                                    readText = 'Right/Parallel Read'
                                }
                            } else {
                                if (value['center_read'] === true) {
                                    readText = "Left/Center Read"
                                } else if (value['perpendicular'] === true) {
                                    readText = 'Left/Perpendicular Read'
                                } else {
                                    readText = 'Left/Parallel Read'
                                }
                            }
                            let streetText = properties['st_name'] + " / " + properties['direction'] + " Bound traffic";

                            let tableVal = {
                                segment_id: value['segment_id'],
                                segment_reg: value['segment_reg'],
                                assignment_id: assignmentid,
                                streetText: streetText,
                                mode: modeText,
                                readText: readText,
                                distance_from_audience: value['distance_from_audience'],
                                assignment_date: value['assignment_date']
                            };
                            //add geojson features to the assingment layer
                            assignmentArray.push(tableVal);
                            assignmentsource.addFeatures(geojsonfeatures);
                            assignmentlayer.setStyle(new Style({
                                    stroke: new Stroke({
                                        color: [0, 131, 154, 1],
                                        width: 12
                                    })

                                })
                            );
                            resolve();
                        })
                    }))
                });
                await Promise.all(assignmentPromises).then(function () {
                    let assignmentLayerExtent = assignmentsource.getExtent();
                    map.getView().fit(assignmentLayerExtent);
                    $('#assignTableTitleText').text(assignmentArray.length + " Total Assignments");
                    loadAssignmentTable()
                })
            }
        })
    }
    function addPanel (geopath_id) {
        pointLayer.getSource().clear();
        let featureRequest = new WFS().writeGetFeature({
            srsName: 'EPSG:3857',
            featurePrefix: 'geopath',
            featureTypes: ['inventory_location_lookup_view'],
            outputFormat: 'application/json',
            filter: equalTo('spot_id', geopath_id)
        });
        let settings = {
            "url": process.env.appurl + "api/lookupPanel",
            "method": "post",
            "content-type": "application/x-www-form-urlencoded",
            "headers": {
                "Authorization": "Bearer " + localStorage.getItem('access_token'),
            },
            "data": {"obj":new XMLSerializer().serializeToString(featureRequest)}
        };
        $.ajax(settings).done(function (response) {
            // console.log(response);
            const newlat = parseFloat(response['features'][0]['properties']['latitude']);
            const newlon = parseFloat(response['features'][0]['properties']['longitude']);
            map.getView().setCenter(transform([newlon, newlat], 'EPSG:4326', 'EPSG:3857'));
            map.getView().setZoom(19);
            let frame_id = response['features'][0]['properties']['frame_id']
            let pointFeatureRequest = new WFS().writeGetFeature({
                srsName: 'EPSG:3857',
                featurePrefix: 'geopath',
                featureTypes: ['inventory_min_view'],
                outputFormat: 'application/json',
                filter: equalTo('frame_id', frame_id)
            });
            let settings = {
                "url": process.env.appurl + "api/getPanel",
                "method": "post",
                "content-type": "application/x-www-form-urlencoded",
                "headers": {
                    "Authorization": "Bearer " + localStorage.getItem('access_token'),
                },
                "data": {"obj": new XMLSerializer().serializeToString(pointFeatureRequest)}
            };
            $.ajax(settings).done(function (response) {
                // console.log(response);
                let pointGeoJson = new GeoJSON().readFeatures(response);
                let rotateval = response['features'][0]['properties']['orientation'];
                pointSource.addFeatures(pointGeoJson);
                pointLayer.setStyle(new Style({
                    image: new Icon({
                        crossOrigin: 'anonymous',
                        src: gp_frame_selected,
                        rotation: degrees_to_radians(rotateval)
                    })
                }));
                showassignments(frame_id)
            })
        })
    }
    addPanel(geopath_id);
}

export function displayAndLoad (geopath_id){
    let geopathid = null;
    geopathid = geopath_id;
    mModal.on('shown.bs.modal', function () {
        mModalTable.html('');
        mModalBody.html('<div id="map" class="map"></div>');
        mModalTitle.text('Map of Assignments for GeopathID: ' + geopathid);
        loadMap(geopathid)
    });
    mModal.modal('show');
    mModal.on('hidden.bs.modal', function () {
        mModalBody.html('');
        mModalTitle.text('');
        mModalTable.html('');
        geopathid = null;
        mModal.modal('dispose');
    })
}









