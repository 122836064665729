import Tabulator from "../node_modules/tabulator-tables/dist/js/tabulator"
import c3 from "../node_modules/c3/c3.js"
import _ from "../node_modules/underscore/underscore"
import {displayAndLoad} from "./map";

//set variables needed later
let responseTable;
let summaryStatsTable;
let operatorSumStatsTable2;
let operatorSumStatsTabulator;
let dmaSumStatsTabulator;
let impCompTabulator;
let issueTabulator;
let impchart;
let issuechart;
let issueStats;
let spdchart;
let circchart;
let circillchart;

//get panel list by division and kick off other functions
export function getListByDivision(formData) {
    $("#factor-table-holder").removeAttr('hidden');
    $("#comp-table-holder").removeAttr('hidden');
    $("#sumstats-table-holder").removeAttr('hidden');
    $("#loading-spinner").removeAttr('hidden');
    let settings = {
        "async": true,
        "crossDomain": true,
        "url": process.env.appurl + "api/getlistbydivision",
        "method": "GET",
        "headers": {
            "content-type": "application/json",
            "Authorization": "Bearer " + localStorage.getItem('access_token')
        },
        "data":formData,
        "dataType":"json"
    };

    $.ajax(settings).done(function (response) {
        // console.log(response);
            responseTable = response;
            createImpCompTable();
            getDmasForSumStats();
            $("#loading-spinner").attr('hidden');
        }
    );
};

//get panel list by dma market and kick off other functions
export function getListByMarket(formData) {
    let settings = {
        "async": true,
        "crossDomain": true,
        "url": process.env.appurl + "api/getlistbymarket",
        "method": "GET",
        "headers": {
            "content-type": "application/json",
            "Authorization": "Bearer " + localStorage.getItem('access_token')
        },
        "data":formData,
        "dataType":"json"
    };

    $("#loading-spinner").show();
    $.ajax(settings).done(function (response) {
            responseTable = response;
            createImpCompTable();
            getDmasForSumStats();
            $("#loading-spinner").hide();
        }
    );
};

//get date data was updated from database
function getUpdateDate (){
    let settings = {
        "async": true,
        "crossDomain": true,
        "url": process.env.appurl + "api/getupdatedate",
        "method": "GET",
        "headers": {
            "content-type": "application/json",
            "Authorization": "Bearer " + localStorage.getItem('access_token')
        },
        "dataType":"json"
    };
    $.ajax(settings).done(function (response) {
        $('#lastUpdateDate').text('Last Data Update: ' + response[0]['last_update_date'])
    })
}

// get the summary statistics from the summary stats table in the database
function getSumStats (dmaList){

    let settings = {
        "async": true,
        "crossDomain": true,
        "url": process.env.appurl + "api/getsumstats",
        "method": "GET",
        "headers": {
            "content-type": "application/json",
            "Authorization": "Bearer " + localStorage.getItem('access_token')
        },
        "data":{dmaList:dmaList},
        "dataType":"json"
    };
    $.ajax(settings).done(function (response) {
            createDmaSumStatsTable(response);
        }
    )
}

// get the unique DMAS within the response table to query for summary statistics
function getDmasForSumStats (){
    const keys = (_.keys(_.countBy(responseTable, function(responseTable) { return responseTable.dma_name; })));
    const dmaList =[];
    $.each(keys, function (index, value) {
        dmaList.push(
            value
        );
    });
    getSumStats(dmaList);
}

// get operator level summary stats
window.getOperatorSumStats = function  (companyName){
    getUpdateDate();
    $.ajax({
        "async": true,
        "crossDomain": true,
        "url": process.env.appurl + "api/getoperatorsumstats",
        "method": "GET",
        "headers": {
            "content-type": "application/json",
            "Authorization": "Bearer " + localStorage.getItem('access_token')
        },
        "data": {"parent_account_name": companyName},
        "dataType": "json",
        "success": function (response) {
            createOperatorSumStatsTable(response);

        }
    });
};

window.getOperatorIssues = function (company){
    $.ajax({
        "async": true,
        "crossDomain": true,
        "url": process.env.appurl + "api/getissuestats",
        "method": "GET",
        "headers": {
            "content-type": "application/json",
            "Authorization": "Bearer " + localStorage.getItem('access_token')
        },
        "data": {"parent_account_name": company},
        "dataType": "json",
        "success": function (response) {
            // console.log(response)
            issueStats = response;
            // console.log(issueStats);
            createOperatorIssueTable(response)

        }
    });
};

//function to alphabetize
function compare(a, b) {
    // Use toUpperCase() to ignore character casing
    const A = a.text.toUpperCase();
    const B = b.text.toUpperCase();

    let comparison = 0;
    if (A > B) {
        comparison = 1;
    } else if (A < B) {
        comparison = -1;
    }
    return comparison;
}

//build parameters for tabulator column select filters based on the unique values in those columns
function mediaParamBuilder(){
    let list = {"":""};
    let mediaData = this.getData().map(a => a.frame_media_name);
    mediaData.sort();
    mediaData.forEach(function(item){
        if(typeof item !== "undefined"){
            list[item] = item;
        }
    });
    return list
}
function divisionParamBuilder(){
    let list = {"":""};
    let divisionData = this.getData().map(a => a.account_name);
    divisionData.sort();
    divisionData.forEach(function(item){
        if(typeof item !== "undefined"){
            list[item] = item;
        }
    });
    return list;

}
function dmaParamBuilder(){
    let list = {"":""};
    let dmaData = this.getData().map(a => a.dma_name);
    dmaData.sort();
    dmaData.forEach(function(item){
        if(typeof item !== "undefined"){
            list[item] = item;
        }
    });
    return list
}
function reviewParamBuilder(){
    let list = {"":""};
    let reviewData = this.getData().map(a => a.status_type_name);
    // console.log(reviewData)
    reviewData.forEach(function(item){
        if(typeof item !== "undefined"){
            list[item] = item;
        }
    });
    return list
}
function issueParamBuilder(){

    let list = {"":""};
    let reviewData = this.getData().map(a => a.status_type_name);
    // console.log(reviewData)
    reviewData.forEach(function(item){
        if(typeof item !== "undefined"){
            list[item] = item;
        }
    });
    return list
}
function dftaParamBuilder(){
    let list = {"":""};
    let dftaData = this.getData().map(a => a.dfta_good);
    dftaData.forEach(function(item){
        if(typeof item !== "undefined"){
            list[item] = item;
        }
    });
    return list
}

//get map icon
const mapIcon = function(cell, formatterParams){ //plain text value
    return "<i class=\"fas fa-map-marked-alt\"></i>";
};

//downloads
function exportOpSumStats() {
    operatorSumStatsTabulator.download("csv", "geopath_operator_data.csv")
}
function exportMarketStats() {
    dmaSumStatsTabulator.download("csv", "geopath_market_data.csv")
}
function exportOpInv() {
    impCompTabulator.download("csv", "geopath_inventory_data.csv")
}
function exportOpIssue() {
    issueTabulator.download("csv", "geopath_audit_data.csv")
}

// aray avg function
const arrAvg = arr => arr.reduce((a,b) => a + b, 0) / arr.length;
const arrSum = arr => arr.reduce((a,b) => a + b, 0);
function weightedMean(arrValues, arrWeights) {

    var result = arrValues.map(function (value, i) {

        var weight = arrWeights[i];
        var sum = value * weight;

        return [sum, weight];
    }).reduce(function (p, c) {

        return [p[0] + c[0], p[1] + c[1]];
    }, [0, 0]);

    return result[0] / result[1];
}

//weight avg calc functions for operator summary table
function adsimpWeightAvgCalc(value,data) {
    let panelsumarray = [];
    let numsumarray = [];
    $.each(data, function (index,value) {
        panelsumarray.push(parseInt(value.panelcount));
        numsumarray.push(parseInt(value.ads5plussum))
    });
    // console.log(panelsumarray);
    // console.log(numsumarray);
    let panelsum = panelsumarray.reduce((a,b) => a + b, 0);
    let numsum = numsumarray.reduce((a,b) => a + b, 0);
    // console.log(numsum,panelsum)
    return  numsum/panelsum
}
function ads18impWeightAvgCalc(value,data) {
    let panelsumarray = [];
    let numsumarray = [];
    $.each(data, function (index,value) {
        panelsumarray.push(parseInt(value.panelcount));
        numsumarray.push(parseInt(value.ads18plussum))
    });
    // console.log(panelsumarray);
    // console.log(numsumarray);
    let panelsum = panelsumarray.reduce((a,b) => a + b, 0);
    let numsum = numsumarray.reduce((a,b) => a + b, 0);
    // console.log(numsum,panelsum)
    return  numsum/panelsum
}
function adsINMRKTimpWeightAvgCalc(value,data) {
    let panelsumarray = [];
    let numsumarray = [];
    $.each(data, function (index,value) {
        panelsumarray.push(parseInt(value.panelcount));
        numsumarray.push(parseInt(value.ads5plusinmrktsum))
    });
    // console.log(panelsumarray);
    // console.log(numsumarray);
    let panelsum = panelsumarray.reduce((a,b) => a + b, 0);
    let numsum = numsumarray.reduce((a,b) => a + b, 0);
    // console.log(numsum,panelsum)
    return  numsum/panelsum
}
function gisimpWeightAvgCalc(value,data) {
    let panelsumarray = [];
    let numsumarray = [];
    $.each(data, function (index,value) {
        panelsumarray.push(parseInt(value.panelcount));
        numsumarray.push(parseInt(value.gis5plussum))
    });
    let panelsum = panelsumarray.reduce((a,b) => a + b, 0);
    let numsum = numsumarray.reduce((a,b) => a + b, 0);
    return  numsum/panelsum
}
function gisimp18WeightAvgCalc(value,data) {
    let panelsumarray = [];
    let numsumarray = [];
    $.each(data, function (index,value) {
        panelsumarray.push(parseInt(value.panelcount));
        numsumarray.push(parseInt(value.gis18plussum))
    });
    let panelsum = panelsumarray.reduce((a,b) => a + b, 0);
    let numsum = numsumarray.reduce((a,b) => a + b, 0);
    return  numsum/panelsum
}
function gisimpINMKRTWeightAvgCalc(value,data) {
    let panelsumarray = [];
    let numsumarray = [];
    $.each(data, function (index,value) {
        panelsumarray.push(parseInt(value.panelcount));
        numsumarray.push(parseInt(value.gis5plusinmrktsum))
    });
    let panelsum = panelsumarray.reduce((a,b) => a + b, 0);
    let numsum = numsumarray.reduce((a,b) => a + b, 0);
    return  numsum/panelsum
}
function adsspdWeightAvgCalc(value,data) {
    let panelsumarray = [];
    let numsumarray = [];
    $.each(data, function (index,value) {
        panelsumarray.push(parseInt(value.panelcount));
        numsumarray.push(parseInt(value.sumadsspd))
    });
    let panelsum = panelsumarray.reduce((a,b) => a + b, 0);
    let numsum = numsumarray.reduce((a,b) => a + b, 0);
    return  numsum/panelsum
}
function gisspdWeightAvgCalc(value,data) {
    let panelsumarray = [];
    let numsumarray = [];
    $.each(data, function (index,value) {
        panelsumarray.push(parseInt(value.panelcount));
        numsumarray.push(parseInt(value.sumgisspd))
    });
    let panelsum = panelsumarray.reduce((a,b) => a + b, 0);
    let numsum = numsumarray.reduce((a,b) => a + b, 0);
    return  numsum/panelsum
}
function adsoccWeightAvgCalc(value,data) {
    let panelsumarray = [];
    let numsumarray = [];
    $.each(data, function (index,value) {
        panelsumarray.push(parseInt(value.panelcount));
        numsumarray.push(parseInt(value.sumadsocc))
    });
    let panelsum = panelsumarray.reduce((a,b) => a + b, 0);
    let numsum = numsumarray.reduce((a,b) => a + b, 0);
    return  numsum/panelsum
}
function gisoccWeightAvgCalc(value,data) {
    let panelsumarray = [];
    let numsumarray = [];
    $.each(data, function (index,value) {
        panelsumarray.push(parseInt(value.panelcount));
        numsumarray.push(parseInt(value.sumgisocc))
    });
    let panelsum = panelsumarray.reduce((a,b) => a + b, 0);
    let numsum = numsumarray.reduce((a,b) => a + b, 0);
    return  numsum/panelsum
}
function adscircWeightAvgCalc(value,data) {
    let panelsumarray = [];
    let numsumarray = [];
    $.each(data, function (index,value) {
        panelsumarray.push(parseInt(value.panelcount));
        numsumarray.push(parseInt(value.sumadscirc))
    });
    let panelsum = panelsumarray.reduce((a,b) => a + b, 0);
    let numsum = numsumarray.reduce((a,b) => a + b, 0);
    return  numsum/panelsum
}
function giscircWeightAvgCalc(value,data) {
    let panelsumarray = [];
    let numsumarray = [];
    $.each(data, function (index,value) {
        panelsumarray.push(parseInt(value.panelcount));
        numsumarray.push(parseInt(value.sumgiscirc))
    });
    let panelsum = panelsumarray.reduce((a,b) => a + b, 0);
    let numsum = numsumarray.reduce((a,b) => a + b, 0);
    return  numsum/panelsum
}
function adscircillWeightAvgCalc(value,data) {
    let panelsumarray = [];
    let numsumarray = [];
    $.each(data, function (index,value) {
        panelsumarray.push(parseInt(value.panelcount));
        numsumarray.push(parseInt(value.sumadscircill))
    });
    let panelsum = panelsumarray.reduce((a,b) => a + b, 0);
    let numsum = numsumarray.reduce((a,b) => a + b, 0);
    return  numsum/panelsum
}
function giscircillWeightAvgCalc(value,data) {
    let panelsumarray = [];
    let numsumarray = [];
    $.each(data, function (index,value) {
        panelsumarray.push(parseInt(value.panelcount));
        numsumarray.push(parseInt(value.sumgiscircill))
    });
    let panelsum = panelsumarray.reduce((a,b) => a + b, 0);
    let numsum = numsumarray.reduce((a,b) => a + b, 0);
    return  numsum/panelsum
}

// footer percentage calcs
function operatorAvgsCalc(value, data) {
    let adsimpsumarray = [];
    let gisimpsumarray =[];
    $.each(data, function (index,value) {
        adsimpsumarray.push(parseInt(value.ads5plussum));
        gisimpsumarray.push(parseInt(value.gis5plussum));
    });
    let adsimpsum = adsimpsumarray.reduce((a,b) => a + b, 0);
    let gisimpsum = gisimpsumarray.reduce((a,b) => a + b, 0);
    return ((gisimpsum-adsimpsum)/adsimpsum)*100
}
function operator18AvgsCalc(value, data) {
    let adsimpsumarray = [];
    let gisimpsumarray =[];
    $.each(data, function (index,value) {
        adsimpsumarray.push(parseInt(value.ads18plussum));
        gisimpsumarray.push(parseInt(value.gis18plussum));
    });
    let adsimpsum = adsimpsumarray.reduce((a,b) => a + b, 0);
    let gisimpsum = gisimpsumarray.reduce((a,b) => a + b, 0);
    return ((gisimpsum-adsimpsum)/adsimpsum)*100
}
function operatorADSInMrktAvgsCalc(value, data) {
    let adstotal = [];
    let adsinmarkettotal =[];
    $.each(data, function (index,value) {
        adstotal.push(parseInt(value.ads5plussum));
        adsinmarkettotal.push(parseInt(value.ads5plusinmrktsum));
    });
    let adsimpsum = adstotal.reduce((a,b) => a + b, 0);
    let adsinmarketimpsum = adsinmarkettotal.reduce((a,b) => a + b, 0);
    return (adsinmarketimpsum/adsimpsum)*100
}
function operatorGISInMrktAvgsCalc(value, data) {
    let gistotal = [];
    let gisinmarkettotal =[];
    $.each(data, function (index,value) {
        gistotal.push(parseInt(value.gis5plussum));
        gisinmarkettotal.push(parseInt(value.gis5plusinmrktsum));
    });
    let gisimpsum = gistotal.reduce((a,b) => a + b, 0);
    let gisinmarketimpsum = gisinmarkettotal.reduce((a,b) => a + b, 0);
    return (gisinmarketimpsum/gisimpsum)*100
}

// cast values as floats for summary stats avgs
const intergerMutator = function(value){
    let val;
    if (value){
        val = parseFloat(value)
    }
    else{
        val = 0
    }
    return val
};

//create true false values for true false filters
const tfMutator = function(value){
    let val;
    if (value === true){
        val = 'True'
    }
    else{
        val = 'False'
    }
    return val
};

//alter review flags based on input
const flagMutator = function (value, data) {
    let status;
    if (value) {
        if (data.status_type_name === 'Unaudited') {
            status = data.status_type_name
        }
        else if (data.status_type_name === 'Audited - Level 1') {
            status = data.status_type_name
        }
        else if (data.status_type_name === 'Audited - Level 9') {
            status = data.status_type_name
        }
        else if (data.status_type_name ==='Published - Measured'){
            status = data.status_type_name
        }
        else if (data.status_type_name ==='Published - Under Review'){
            status = data.status_type_name
        }
        else if (data.status_type_name ==='Published - Suppressed'){
            status = data.status_type_name
        }
        else {
            status = 'Other Status'
        }
    }
    else {
        status = 'Other Status'
    }
    return status
};

//create imp factor charts based on the selected rows in the comp table
function createIndividualPanelCharts (selectedRows) {
    let selectedRowGPID = selectedRows[0]['_row']['data']['geopath_panel_id'];
    let old_speed = selectedRows[0]['_row']['data']['ads_avg_spd'];
    let new_speed = selectedRows[0]['_row']['data']['gis_avg_spd'];
    let old_circ = selectedRows[0]['_row']['data']['ads_total_circ'];
    let new_circ = selectedRows[0]['_row']['data']['gis_total_circ'];
    let old_circ_ill = selectedRows[0]['_row']['data']['ads_total_circ_ill'];
    let new_circ_ill = selectedRows[0]['_row']['data']['gis_total_circ_ill'];
    let old_occ = selectedRows[0]['_row']['data']['ads_avg_occ'];
    let new_occ = selectedRows[0]['_row']['data']['gis_avg_occ'];
    let selectedMediaType = selectedRows[0]['_row']['data']['frame_media_name'];
    let selectedDMA = selectedRows[0]['_row']['data']['dma_name'];
    let avgSelectors = {dma_name: selectedDMA, frame_media_name: selectedMediaType};
    getAvgsForFactorCharts(old_circ_ill, old_circ, old_speed, new_speed, new_circ_ill, new_circ, old_occ, new_occ, avgSelectors);
    //create flag button and listener
    $("#flagButton").removeAttr('hidden');
    $("#flagButton").on('click', function () {
        $("#modalPanelID").val(selectedRowGPID)
    })
}

//create panel level imp comp table w/ row selection and table function call
function createImpCompTable() {
    // console.log(responseTable)
    $("#comp-table-holder").removeAttr('hidden');
    impCompTabulator = new Tabulator("#comp-table", {
        selectable: 1,
        selectableRollingSelection:false,
        selectablePersistence:false,
        tooltipsHeader:function(column){
            let title = column.getDefinition().title;
            let alttext;
            if( title === "Unit Count"){
                alttext = "Count of inventory receiving measures"
            }
            else if( title === "ADS Default DFTA"){
                alttext = "Legacy methods used actual distance from the audience \"DFTA\" values by media type and road class for this piece of inventory"
            }
            else if( title === "Old - Avg. Wkly Imp. 5+"){
                alttext = "Existing Methods - Average Weekly Impressions (Persons 5+) Blank if inventory is new"
            }
            else if( title === "New - Avg. Wkly Imp. 5+"){
                alttext = "New Methods - Average Weekly Impressions (Persons 5+)"
            }
            else if( title === "% Diff. - Avg. Wkly Imp. 5+"){
                alttext = "Percentage Difference (Existing to New) in Average Weekly Impressions (Persons 5+)"
            }
            else if( title === "Old - Avg. Wkly Imp. 18+"){
                alttext = "Existing Methods - Average Weekly Impressions (Persons 18+)"
            }
            else if( title === "New - Avg. Wkly Imp. 18+"){
                alttext = "New Methods - Average Weekly Impressions (Persons 18+)"
            }
            else if( title === "% Diff. - Avg. Wkly Imp. 18+"){
                alttext = "Percentage Difference (Existing to New) in Average Weekly Impressions (Persons 18+)"
            }
            else if( title === "Old - Avg. Wkly Imp. 5+ In Market"){
                alttext = "Existing Methods - Average Weekly In Market Impressions (Persons 5+)"
            }
            else if( title === "New - Avg. Wkly Imp. 5+"){
                alttext = "New Methods - Average Weekly In Market Impressions (Persons 5+)"
            }
            else if( title === "Old - Avg. Speed"){
                alttext = "Existing Methods - Average Speed (mph)"
            }
            else if( title === "New - Avg. Speed"){
                alttext = "New Methods - Average Speed (mph)"
            }
            else if( title === "Old - Avg. Persons/Veh"){
                alttext = "Existing Methods - Average Number of Persons 5+ per Vehicle"
            }
            else if( title === "New - Avg. Persons/Veh"){
                alttext = "New Methods - Average Number of Persons 5+ per Vehicle"
            }
            else if( title === "Old - Avg. Wkly. Circ."){
                alttext = "Existing Methods - Average Weekly Circulation (Persons 5+) at All Times"
            }
            else if( title === "New - Avg. Wkly. Circ."){
                alttext = "New Methods - Average Weekly Circulation (Persons 5+) at All Times"
            }
            else if( title === "Old - Avg. Wkly. Illum. Circ."){
                alttext = "Existing Methods - Average Weekly Circulation (Persons 5+) During Illuminated Periods"
            }
            else if( title === "New - Avg. Wkly Illum. Circ.") {
                alttext = "New Methods - Average Weekly Circulation (Persons 5+) During Illuminated Periods"
            }
            else {
                alttext = title
            }
            return  alttext;
        },
        data: responseTable,
        height: '30vh',
        layout: "fitData",
        columns: [
            {
                title: "Division Name",
                field: "account_name",
                headerFilter: "select",
                headerFilterParams: divisionParamBuilder,
                headerFilterFunc: "=",
                bottomCalc: 'count',
                bottomCalcFormatter: "money",
                bottomCalcFormatterParams: {
                    thousand: ",",
                    symbol: "Total Records: ",
                    precision: 0
                }
            },
            {
                title: "DMA Name",
                field: "dma_name",
                visible: false},
            {
                title: "Media Type",
                field: "frame_media_name",
                headerFilter: "select",
                headerFilterParams: mediaParamBuilder,
                headerFilterFunc: "="
            },
            {
                title: "Operator Unit ID",
                field: "plant_spot_id",
                headerFilter: "input"},
            {
                title: "Geopath ID",
                field: "geopath_panel_id",
                headerFilter: "number"
            },
            {
                title:"Map",
                formatter:mapIcon,
                width:40,
                align:"center",
                cellClick:function(e,cell){
                    displayAndLoad(cell.getRow().getData().geopath_panel_id)
                }
            },
            {
                title: "Review Status",
                field: "status_type_name",
                mutator: flagMutator,
                headerFilter: "select",
                headerFilterParams: reviewParamBuilder,
                headerFilterFunc: "="
            },
            {
                title: "ADS Actual DFTA",
                field: "dfta_good",
                mutator: tfMutator,
                headerFilter: "select",
                headerFilterParams: dftaParamBuilder,
                headerFilterFunc: "="
            },
            {
                title: "Old - Avg. Wkly Imp. 5+",
                field: "ads_ag5_total_imp",
                formatter: "money", formatterParams: {
                    thousand: ",",
                    precision: 0,
                }
            },
            {
                title: "New - Avg. Wkly Imp. 5+",
                field: "gis_ag5_total_imp",
                formatter: "money",
                formatterParams: {
                    thousand: ",",
                    precision: 0,
                }
            },
            {
                title: "% Diff. - Avg. Wkly Imp. 5+",
                field: "ag5_total_imp_per_dif",
                formatter: "money",
                formatterParams: {
                    precision: 0,
                    symbol: '%',
                    symbolAfter: "p"
                }
            },
            {
                title: "Old - Avg. Wkly Imp. 18+",
                field: "ads_ag18_total_imp",
                formatter: "money", formatterParams: {
                    thousand: ",",
                    precision: 0,
                }
            },
            {
                title: "New - Avg. Wkly Imp. 18+",
                field: "gis_ag18_total_imp",
                formatter: "money",
                formatterParams: {
                    thousand: ",",
                    precision: 0,
                }
            },
            {
                title: "% Diff. - Avg. Wkly Imp. 18+",
                field: "ag18_total_imp_per_dif",
                formatter: "money",
                formatterParams: {
                    precision: 0,
                    symbol: '%',
                    symbolAfter: "p"
                }
            },
            {
                title: "Old - Avg. Wkly Imp. 5+ In Market",
                field: "ads_ag5_total_inmrkt_imp",
                formatter: "money", formatterParams: {
                    thousand: ",",
                    precision: 0,
                }
            },
            {
                title: "Old - Avg. Wkly Imp. 5+ In Market %",
                field: "ads_ag5_inmrkt_per",
                formatter: "money",
                formatterParams: {
                    precision: 0,
                    symbol: '%',
                    symbolAfter: "p"
                }
            },
            {
                title: "New - Avg. Wkly Imp. 5+ In Market",
                field: "gis_ag5_total_inmrkt_imp",
                formatter: "money",
                formatterParams: {
                    thousand: ",",
                    precision: 0,
                }
            },
            {
                title: "New - Avg. Wkly Imp. 5+ In Market %",
                field: "gis_ag5_inmrkt_per",
                formatter: "money",
                formatterParams: {
                    precision: 0,
                    symbol: '%',
                    symbolAfter: "p"
                }
            },
            {
                title: "Old - Avg. Speed",
                field: "ads_avg_spd",
                formatter:"money",
                formatterParams:{
                    thousand:",",
                    precision:0,
                }
            },
            {
                title: "New - Avg. Speed",
                field: "gis_avg_spd",
                formatter:"money",
                formatterParams:{
                    thousand:",",
                    precision:0,
                }
            },
            {
                title: "Old - Avg. Persons/Veh",
                field: "ads_avg_occ",
                formatter:"money",
                formatterParams:{
                    thousand:",",
                    precision:2,
                }
            },
            {
                title: "New - Avg. Persons/Veh",
                field: "gis_avg_occ",
                formatter:"money",
                formatterParams:{
                    thousand:",",
                    precision:2,
                }
            },
            {
                title: "Old - Avg. Total Circ.",
                field: "ads_total_circ",
                formatter:"money",
                formatterParams:{
                    thousand:",",
                    precision:0,
                }
            },
            {
                title: "New - Avg. Total Circ.",
                field: "gis_total_circ",
                formatter:"money",
                formatterParams:{
                    thousand:",",
                    precision:0,
                }
            },
            {
                title: "Old - Avg. Total Illum. Circ.",
                field: "ads_total_circ_ill",
                formatter:"money",
                formatterParams:{
                    thousand:",",
                    precision:0,
                }
            },
            {
                title: "New - Avg. Total Illum. Circ.",
                field: "gis_total_circ_ill",
                formatter:"money",
                formatterParams:{
                    thousand:",",
                    precision:0,
                }
            }
        ],
        rowFormatter: function (row) {
            let data = row.getData();
            if (data.status_type_name !== 'Published - Measured') {
                    row.getElement().style.backgroundColor = '#fcdbb4'
            }
            else {
                if (row._row.element.classList[2] === 'tabulator-row-odd') {
                    row.getElement().style.backgroundColor = '#fff'
                }
                else {
                    row.getElement().style.backgroundColor = '#efefef'
                }
            }
        },
        dataLoaded: function () {
            this.getColumn("frame_media_name").reloadHeaderFilter();
            this.getColumn("account_name").reloadHeaderFilter();
            this.getColumn("status_type_name").reloadHeaderFilter();
            this.getColumn("dfta_good").reloadHeaderFilter();

        },
        rowDeselected: function (row) {
            row.reformat()
        },
        rowClick: function (e, row,) {
            $('#chart-container').removeAttr("hidden");
            let oldRow = impCompTabulator.getSelectedRows();
            impCompTabulator.deselectRow(oldRow);
            impCompTabulator.selectRow(row);
            row.getElement().style.backgroundColor = '#9ebcec';
            let selectedRows = impCompTabulator.getSelectedRows();
            let selectedRowGPID = selectedRows[0]['_row']['data']['geopath_panel_id'];
            $('#panelHeading').text('Unit Detail Charts (Unit ' +selectedRowGPID+' Only)');
            let old_imps = Math.round(selectedRows[0]['_row']['data']['ads_ag5_total_imp']);
            let new_imps = Math.round(selectedRows[0]['_row']['data']['gis_ag5_total_imp']);
            let selectedMediaType = selectedRows[0]['_row']['data']['frame_media_name'];
            let selectedDMA = selectedRows[0]['_row']['data']['dma_name'];
            let avgSelectors = {dma_name: selectedDMA, frame_media_name: selectedMediaType};
            createImpCompChart(old_imps, new_imps, avgSelectors);
            createIndividualPanelCharts(selectedRows);
            // createImpFactorCompTable(selectedRowGPID);
        },
        dataFiltered: function () {
        }
    });
    impCompTabulator.setSort([
        {column:"account_name", dir:"asc"}
    ])
    //get info for tables and kick off the supplemental tables
    // impFactorCompTable.setFilter("geopath_panel_id","=",selectedRowGPID);
    // impFactorCompTable.selectRow(true);

}

//create sum stats table
function createDmaSumStatsTable(sumStatsTable) {
    $("#sumstats-table-holder").removeAttr('hidden');
    dmaSumStatsTabulator = new Tabulator("#sumstats-table", {
        data:sumStatsTable,
        height: '22vh',
        tooltipsHeader:function(column){
            let title = column.getDefinition().title;
            let alttext;
            if( title === "Unit Count"){
                alttext = "Count of inventory receiving measures"
            }
            else if( title === "Old - Avg. Wkly. Imp. 5+"){
                alttext = "Existing Methods - Average Weekly Impressions (Persons 5+)"
            }
            else if( title === "New - Avg. Wkly. Imp. 5+"){
                alttext = "New Methods - Average Weekly Impressions (Persons 5+)"
            }
            else if( title === "% Diff. - Avg. Wkly. Imp. 5+"){
                alttext = "Percentage Difference (Existing to New) in Average Weekly Impressions (Persons 18+)"
            }
            else if( title === "Old - Avg. Wkly. Imp. 18+"){
                alttext = "Existing Methods - Average Weekly Impressions (Persons 18+)"
            }
            else if( title === "New - Avg. Wkly. Imp. 18+"){
                alttext = "New Methods - Average Weekly Impressions (Persons 5+)"
            }
            else if( title === "% Diff. - Avg. Wkly. Imp. 18+"){
                alttext = "Percentage Difference (Existing to New) in Average Weekly Impressions (Persons 18+)"
            }
            else if( title === "Old - Avg. Wkly. In Market Imp. 5+"){
                alttext = "Existing Methods - Average Weekly In Market Impressions (Persons 5+)"
            }
            else if( title === "New - Avg. Wkly. In Market Imp. 5+"){
                alttext = "New Methods - Average Weekly In Market Impressions (Persons 5+)"
            }
            else if( title === "Old - Avg. Wkly In Market Imp. 5+ %"){
                alttext = "Existing Methods - Average Weekly In Market Impressions Percentage (Persons 5+)"
            }
            else if( title === "New - Avg. Wkly. In Market Imp. 5+ %"){
                alttext = "New Methods - Average Weekly In Market Impressions Percentage(Persons 5+)"
            }
            else if( title === "Old - Avg. Speed"){
                alttext = "Existing Methods - Average Speed (mph)"
            }
            else if( title === "New - Avg. Speed"){
                alttext = "New Methods - Average Speed (mph)"
            }
            else if( title === "Old - Avg. Persons/Veh"){
                alttext = "Existing Methods - Average Number of Persons 5+ per Vehicle"
            }
            else if( title === "New - Avg. Persons/Veh"){
                alttext = "New Methods - Average Number of Persons 5+ per Vehicle"
            }
            else if( title === "Old - Avg. Total Circ."){
                alttext = "Existing Methods - Average Weekly Circulation (Persons 5+) at All Times"
            }
            else if( title === "New - Avg. Total Circ."){
                alttext = "New Methods - Average Weekly Circulation (Persons 5+) at All Times"
            }
            else if( title === "Old - Avg. Total Illum. Circ."){
                alttext = "Existing Methods - Average Weekly Circulation (Persons 5+) During Illuminated Periods"
            }
            else if( title === "New - Avg. Total Illum. Circ.") {
                alttext = "New Methods - Average Weekly Circulation (Persons 5+) During Illuminated Periods"
            }
            else {
                alttext = title
            }
            return  alttext;
        },
        layout:"fitData",
        initialSort: [
            {column:"dma_name", dir:"asc"}
        ],
        columns: [
            {
                title: "DMA Name",
                field: "dma_name",
                headerFilter:"select",
                headerFilterParams:dmaParamBuilder,
                headerFilterFunc:"="},
            {
                title: "Media Type",
                field: "frame_media_name",
                headerFilter:"select",
                headerFilterParams:mediaParamBuilder,
                headerFilterFunc:"="
            },
            {
                title: "Old - Avg. Wkly Imp. 5+",
                field: "ads5plusavg",
                mutator:intergerMutator,
                formatter:"money",
                formatterParams:{
                    thousand:",",
                    precision:0,
                },
                bottomCalc:adsimpWeightAvgCalc,
                bottomCalcFormatter:"money",
                bottomCalcFormatterParams:{
                    thousand:",",
                    symbol:"Avg: ",
                    precision:0}
                },
            {
                title: "New - Avg. Wkly Imp. 5+",
                field: "gis5plusavg",
                mutator:intergerMutator,
                formatter:"money",
                formatterParams:{
                    thousand:",",
                    precision:0,
                },
                bottomCalc:gisimpWeightAvgCalc,
                bottomCalcFormatter:"money",
                bottomCalcFormatterParams:{
                    thousand:",",
                    symbol:"Avg: ",
                    precision:0}
                },
            {
                title: "% Diff. - Avg. Wkly Imp. 5+",
                field: "ag5impperdiff",
                mutator:intergerMutator,
                formatter:"money",
                formatterParams:{
                    precision:0,
                    symbol:'%',
                    symbolAfter:"p"
                },
                bottomCalc:operatorAvgsCalc,
                bottomCalcFormatter:"money",
                bottomCalcFormatterParams:{
                    symbol:'%',
                    symbolAfter:"p",
                    precision:0
                }
                },
            {
                title: "Old - Avg. Wkly Imp. 18+",
                field: "ads18plusavg",
                mutator:intergerMutator,
                formatter:"money",
                formatterParams:{
                    thousand:",",
                    precision:0,
                },
                bottomCalc:ads18impWeightAvgCalc,
                bottomCalcFormatter:"money",
                bottomCalcFormatterParams:{
                    thousand:",",
                    symbol:"Avg: ",
                    precision:0}
            },
            {
                title: "New - Avg. Wkly Imp. 18+",
                field: "gis18plusavg",
                mutator:intergerMutator,
                formatter:"money",
                formatterParams:{
                    thousand:",",
                    precision:0,
                },
                bottomCalc:gisimp18WeightAvgCalc,
                bottomCalcFormatter:"money",
                bottomCalcFormatterParams:{
                    thousand:",",
                    symbol:"Avg: ",
                    precision:0}
            },
            {
                title: "% Diff. - Avg. Wkly Imp. 18+",
                field: "ag18impperdiff",
                mutator:intergerMutator,
                formatter:"money",
                formatterParams:{
                    precision:0,
                    symbol:'%',
                    symbolAfter:"p"
                },
                bottomCalc:operator18AvgsCalc,
                bottomCalcFormatter:"money",
                bottomCalcFormatterParams:{
                    symbol:'%',
                    symbolAfter:"p",
                    precision:0
                }
            },
            {
                title: "Old - Avg. Wkly In Market Imp. 5+",
                field: "ads5plusinmrktavg",
                mutator:intergerMutator,
                formatter:"money",
                formatterParams:{
                    thousand:",",
                    precision:0,
                },
                bottomCalc:adsINMRKTimpWeightAvgCalc,
                bottomCalcFormatter:"money",
                bottomCalcFormatterParams:{
                    thousand:",",
                    symbol:"Avg: ",
                    precision:0}
            },
            {
                title: "Old - Avg. Wkly In Market Imp. 5+ %",
                field: "adsinmrktper",
                mutator:intergerMutator,
                formatter:"money",
                formatterParams:{
                    precision:0,
                    symbol:'%',
                    symbolAfter:"p"
                },
                bottomCalc:operatorADSInMrktAvgsCalc,
                bottomCalcFormatter:"money",
                bottomCalcFormatterParams:{
                    symbol:'%',
                    symbolAfter:"p",
                    precision:0
                }
            },
            {
                title: "New - Avg. Wkly In Market Imp. 5+",
                field: "gis5plusinmrktavg",
                mutator:intergerMutator,
                formatter:"money",
                formatterParams:{
                    thousand:",",
                    precision:0,
                },
                bottomCalc:gisimpINMKRTWeightAvgCalc,
                bottomCalcFormatter:"money",
                bottomCalcFormatterParams:{
                    thousand:",",
                    symbol:"Avg: ",
                    precision:0}
            },
            {
                title: "New - Avg. Wkly In Market Imp. 5+ %",
                field: "gisinmrktper",
                mutator:intergerMutator,
                formatter:"money",
                formatterParams:{
                    precision:0,
                    symbol:'%',
                    symbolAfter:"p"
                },
                bottomCalc:operatorGISInMrktAvgsCalc,
                bottomCalcFormatter:"money",
                bottomCalcFormatterParams:{
                    symbol:'%',
                    symbolAfter:"p",
                    precision:0
                }
            },
            {
                title: "Old - Avg. Speed",
                field: "avgadsspd",
                mutator:intergerMutator,
                formatter:"money",
                formatterParams:{
                    thousand:",",
                    precision:0,
                },
                bottomCalc:adsspdWeightAvgCalc,
                bottomCalcFormatter:"money",
                bottomCalcFormatterParams:{
                    thousand:",",
                    symbol:"Avg: ",
                    precision:0}
            },
            {
                title: "New - Avg. Speed",
                field: "avggisspd",
                mutator:intergerMutator,
                formatter:"money",
                formatterParams:{
                    thousand:",",
                    precision:0,
                },
                bottomCalc:gisspdWeightAvgCalc,
                bottomCalcFormatter:"money",
                bottomCalcFormatterParams:{
                    thousand:",",
                    symbol:"Avg: ",
                    precision:0}
                },
            {
                title: "Old - Avg. Persons/Veh",
                field: "avgadsocc",
                mutator:intergerMutator,
                formatter:"money",
                formatterParams:{
                    thousand:",",
                    precision:2,
                },
                bottomCalc:adsoccWeightAvgCalc,
                bottomCalcFormatter:"money",
                bottomCalcFormatterParams:{
                    thousand:",",
                    symbol:"Avg: ",
                    precision:2}
                },
            {
                title: "New - Avg. Persons/Veh",
                field: "avggisocc",
                mutator:intergerMutator,
                formatter:"money",
                formatterParams:{
                    thousand:",",
                    precision:2,
                },
                bottomCalc:gisoccWeightAvgCalc,
                bottomCalcFormatter:"money",
                bottomCalcFormatterParams:{
                    thousand:",",
                    symbol:"Avg: ",
                    precision:2}
                },
            {
                title: "Old - Avg. Total Circ.",
                field: "avgadscirc",
                mutator:intergerMutator,
                formatter:"money",
                formatterParams:{
                    thousand:",",
                    precision:0,
                },
                bottomCalc:adscircWeightAvgCalc,
                bottomCalcFormatter:"money",
                bottomCalcFormatterParams:{
                    thousand:",",
                    symbol:"Avg: ",
                    precision:0}
                },
            {
                title: "New - Avg. Total Circ.",
                field: "avggiscirc",
                mutator:intergerMutator,
                formatter:"money",
                formatterParams:{
                    thousand:",",
                    precision:0,
                },
                bottomCalc:giscircWeightAvgCalc,
                bottomCalcFormatter:"money",
                bottomCalcFormatterParams:{
                    thousand:",",
                    symbol:"Avg: ",
                    precision:0}
                },
            {
                title: "Old - Avg. Total Illum. Circ.",
                field: "avgadscircill",
                mutator:intergerMutator,
                formatter:"money",
                formatterParams:{
                    thousand:",",
                    precision:0,
                },
                bottomCalc:adscircillWeightAvgCalc,
                bottomCalcFormatter:"money",
                bottomCalcFormatterParams:{
                    thousand:",",
                    symbol:"Avg: ",
                    precision:0}
                },
            {
                title: "New - Avg. Total Illum. Circ.",
                field: "avggiscircill",
                formatter:"money",
                mutator:intergerMutator,
                formatterParams:{
                    thousand:",",
                    precision:0,
                },
                bottomCalc:giscircillWeightAvgCalc,
                bottomCalcFormatter:"money",
                bottomCalcFormatterParams:{
                    thousand:",",
                    symbol:"Avg: ",
                    precision:0}
                },
        ],
        dataLoaded: function (){
            this.getColumn("frame_media_name").reloadHeaderFilter();
            this.getColumn("dma_name").reloadHeaderFilter();
        }
    });
    dmaSumStatsTabulator.setSort([
        {column:"dma_name", dir:"asc"}
    ]);
    summaryStatsTable = sumStatsTable
}

//create operator issue table
function createOperatorIssueTable(operatorIssues) {
    // console.log(operatorIssues)
    issueTabulator = new Tabulator("#issue-table", {
        data:operatorIssues,
        height: '30vh',
        layout:"fitColumns",
        initialSort: [
            {column:"dma_name", dir:"asc"}
        ],
        tooltipsHeader:function(column){
            let title = column.getDefinition().title;
            let alttext;
            if( title === "Status"){
                alttext = "Audit Status Assigned by Geopath"
            }
            else if( title === "Spot Count"){
                alttext = "Number of Spots that correspond to that status"
            }
            else {
                alttext = title
            }
            return  alttext;
        },
        columns: [
            {
                title: "DMA Name",
                field: "dma_name",
                headerFilter:"select",
                headerFilterParams:dmaParamBuilder,
                headerFilterFunc:"="
            },
            {
                title: "Media Type",
                field: "frame_media_name",
                headerFilter:"select",
                headerFilterParams:mediaParamBuilder,
                headerFilterFunc:"="
            },
            {
                title:"Division Name",
                field:"account_name",
                headerFilter:"select",
                headerFilterParams:divisionParamBuilder,
                headerFilterFunc:"="
            },
            {
                title:"Status",
                field:"status_type_name",
                headerFilter:"select",
                mutator: flagMutator,
                headerFilterParams:issueParamBuilder,
                headerFilterFunc:"="
            },
            {
                title:"Spot Count",
                field:"spot_count",
                bottomCalc:'sum',
                bottomCalcFormatter:"money",
                bottomCalcFormatterParams:{
                    thousand:",",
                    symbol:"Total: ",
                    precision:0
                }
            }
        ],
        dataLoaded: function (){
            this.getColumn("frame_media_name").reloadHeaderFilter();
            this.getColumn("account_name").reloadHeaderFilter();
            this.getColumn("dma_name").reloadHeaderFilter();
            this.getColumn("status_type_name").reloadHeaderFilter();
            createIssueChartSums()
        },
        renderComplete:function(){
            issueStats = this.getData(true);
            createIssueChartSums()
        }
    });
    issueTabulator.setSort([
        {column:"dma_name", dir:"asc"}
        ]);
    $("#issueModal").on('shown.bs.modal', function () {
        issueTabulator.redraw(true)
    });

}

//create sum stats table
function createOperatorSumStatsTable(operatorSumStatsTable) {
    // console.log(operatorSumStatsTable)
    $("#operatorSumStatsButtonHolder").html('<button id="btn-operatorsumstatsdownload" class="btn btn-outline-info btn-sm " title="Download this table"><i class="fas fa-download"></i></button>');
    $("#btn-operatorsumstatsdownload").click(exportOpSumStats);
    operatorSumStatsTabulator = new Tabulator("#operatorsumstats-table", {
        data:operatorSumStatsTable,
        height: '22vh',
        tooltipsHeader:function(column){
            let title = column.getDefinition().title;
            let alttext;
            if( title === "Unit Count"){
                alttext = "Count of inventory receiving measures"
            }
            else if( title === "Old - Avg. Wkly. Imp. 5+"){
                alttext = "Existing Methods - Average Weekly Impressions (Persons 5+)"
            }
            else if( title === "New - Avg. Wkly. Imp. 5+"){
                alttext = "New Methods - Average Weekly Impressions (Persons 5+)"
            }
            else if( title === "% Diff. - Avg. Wkly. Imp. 5+"){
                alttext = "Percentage Difference (Existing to New) in Average Weekly Impressions (Persons 5+)"
            }
            else if( title === "Old - Avg. Speed"){
                alttext = "Existing Methods - Average Speed (mph)"
            }
            else if( title === "New - Avg. Speed"){
                alttext = "New Methods - Average Speed (mph)"
            }
            else if( title === "Old - Avg. Persons/Veh"){
                alttext = "Existing Methods - Average Number of Persons 5+ per Vehicle"
            }
            else if( title === "New - Avg. Persons/Veh"){
                alttext = "New Methods - Average Number of Persons 5+ per Vehicle"
            }
            else if( title === "Old - Avg. Wkly. Circ."){
                alttext = "Existing Methods - Average Weekly Circulation (Persons 5+) at All Times"
            }
            else if( title === "New - Avg. Wkly. Circ."){
                alttext = "New Methods - Average Weekly Circulation (Persons 5+) at All Times"
            }
            else if( title === "Old - Avg. Wkly In Market Imp. 5+ %"){
                alttext = "Existing Methods - Average Weekly In Market Impressions Percentage (Persons 5+)"
            }
            else if( title === "New - Avg. Wkly. In Market Imp. 5+ %"){
                alttext = "New Methods - Average Weekly In Market Impressions Percentage(Persons 5+)"
            }
            else if( title === "Old - Avg. Wkly. Illum. Circ."){
                alttext = "Existing Methods - Average Weekly Circulation (Persons 5+) During Illuminated Periods"
            }
            else if( title === "New - Avg. Wkly Illum. Circ.") {
                alttext = "New Methods - Average Weekly Circulation (Persons 5+) During Illuminated Periods"
            }
            else {
                alttext = title
            }
            return  alttext;
        },
        layout:"fitData",
        initialSort: [
            {column:"dma_name", dir:"asc"}
        ],
        columns: [
            {
                title: "DMA Name",
                field: "dma_name",
                headerFilter:"select",
                headerFilterParams:dmaParamBuilder,
                headerFilterFunc:"=",
            },
            {
                title: "Division Name",
                field: "account_name",
                headerFilter:"select",
                headerFilterParams:divisionParamBuilder,
                headerFilterFunc:"="
            },
            {
                title: "Media Type",
                field: "frame_media_name",
                headerFilter:"select",
                headerFilterParams:mediaParamBuilder,
                headerFilterFunc:"=",headerFilterPlaceholder:"Select Media Type"
            },
            {
                title: "Unit Count",field: "panelcount",
                mutator:intergerMutator,
                formatter:"money",
                formatterParams:{
                    thousand:",",
                    precision:0
                },
                bottomCalc:'sum',
                bottomCalcFormatter:"money",
                bottomCalcFormatterParams:{
                    thousand:",",
                    symbol:"Total: ",
                    precision:0
                }
            },
            {
                title: "Old - Avg. Wkly. Imp. 5+",
                field: "ads5plusavg",
                mutator:intergerMutator,
                formatter:"money",
                formatterParams:{
                    thousand:",",
                    precision:0
                },
                bottomCalc:adsimpWeightAvgCalc,
                bottomCalcFormatter:"money",
                bottomCalcFormatterParams:{
                    thousand:",",
                    symbol:"Avg: ",
                    precision:0
                }
                },
            {
                title: "New - Avg. Wkly. Imp. 5+",
                field: "gis5plusavg",
                mutator:intergerMutator,
                formatter:"money",
                formatterParams:{
                    thousand:",",
                    precision:0
                },
                bottomCalc:gisimpWeightAvgCalc,
                bottomCalcFormatter:"money",
                bottomCalcFormatterParams:{
                    thousand:",",
                    symbol:"Avg: ",
                    precision:0
                }
                },
            {
                title: "% Diff. - Avg. Wkly. Imp. 5+",
                field: "ag5impperdiff",
                mutator:intergerMutator,
                formatter:"money",
                formatterParams:{
                    precision:0,
                    symbol:'%',
                    symbolAfter:"p"
                },
                bottomCalc:operatorAvgsCalc,
                bottomCalcFormatter:"money",
                bottomCalcFormatterParams:{
                    symbol:'%',
                    symbolAfter:"p",
                    precision:0
                }
                },
            {
                title: "Old - Avg. Wkly. Imp. 18+",
                field: "ads18plusavg",
                mutator:intergerMutator,
                formatter:"money",
                formatterParams:{
                    thousand:",",
                    precision:0
                },
                bottomCalc:ads18impWeightAvgCalc,
                bottomCalcFormatter:"money",
                bottomCalcFormatterParams:{
                    thousand:",",
                    symbol:"Avg: ",
                    precision:0
                }
            },
            {
                title: "New - Avg. Wkly. Imp. 18+",
                field: "gis18plusavg",
                mutator:intergerMutator,
                formatter:"money",
                formatterParams:{
                    thousand:",",
                    precision:0
                },
                bottomCalc:gisimp18WeightAvgCalc,
                bottomCalcFormatter:"money",
                bottomCalcFormatterParams:{
                    thousand:",",
                    symbol:"Avg: ",
                    precision:0
                }
            },
            {
                title: "% Diff. - Avg. Wkly. Imp. 18+",
                field: "ag18impperdiff",
                mutator:intergerMutator,
                formatter:"money",
                formatterParams:{
                    precision:0,
                    symbol:'%',
                    symbolAfter:"p"
                },
                bottomCalc:operator18AvgsCalc,
                bottomCalcFormatter:"money",
                bottomCalcFormatterParams:{
                    symbol:'%',
                    symbolAfter:"p",
                    precision:0
                }
            },
            {
                title: "Old - Avg. Wkly. In Market Imp. 5+",
                field: "ads5plusinmrktavg",
                mutator:intergerMutator,
                formatter:"money",
                formatterParams:{
                    thousand:",",
                    precision:0
                },
                bottomCalc:adsINMRKTimpWeightAvgCalc,
                bottomCalcFormatter:"money",
                bottomCalcFormatterParams:{
                    thousand:",",
                    symbol:"Avg: ",
                    precision:0
                }
            },
            {
                title: "Old - Avg. Wkly In Market Imp. 5+ %",
                field: "adsinmrktper",
                mutator:intergerMutator,
                formatter:"money",
                formatterParams:{
                    precision:0,
                    symbol:'%',
                    symbolAfter:"p"
                },
                bottomCalc:operatorADSInMrktAvgsCalc,
                bottomCalcFormatter:"money",
                bottomCalcFormatterParams:{
                    symbol:'%',
                    symbolAfter:"p",
                    precision:0
                }
            },
            {
                title: "New - Avg. Wkly. In Market Imp. 5+",
                field: "gis5plusinmrktavg",
                mutator:intergerMutator,
                formatter:"money",
                formatterParams:{
                    thousand:",",
                    precision:0
                },
                bottomCalc:gisimpINMKRTWeightAvgCalc,
                bottomCalcFormatter:"money",
                bottomCalcFormatterParams:{
                    thousand:",",
                    symbol:"Avg: ",
                    precision:0
                }
            },
            {
                title: "New - Avg. Wkly In Market Imp. 5+ %",
                field: "gisinmrktper",
                mutator:intergerMutator,
                formatter:"money",
                formatterParams:{
                    precision:0,
                    symbol:'%',
                    symbolAfter:"p"
                },
                bottomCalc:operatorGISInMrktAvgsCalc,
                bottomCalcFormatter:"money",
                bottomCalcFormatterParams:{
                    symbol:'%',
                    symbolAfter:"p",
                    precision:0
                }
            },
            {
                title: "Old - Avg. Speed",
                field: "avgadsspd",
                mutator:intergerMutator,
                formatter:"money",
                formatterParams:{
                    precision:0
                },
                bottomCalc:adsspdWeightAvgCalc,
                bottomCalcFormatter:"money",
                bottomCalcFormatterParams:{
                    thousand:",",
                    symbol:"Avg: ",
                    precision:0
                }
                },
            {
                title: "New - Avg. Speed",
                field: "avggisspd",
                mutator:intergerMutator,
                formatter:"money",
                formatterParams:{
                    precision:0
                },
                bottomCalc:gisspdWeightAvgCalc,
                bottomCalcFormatter:"money",
                bottomCalcFormatterParams:{
                    thousand:",",
                    symbol:"Avg: ",
                    precision:0
                }
                },
            {
                title: "Old - Avg. Persons/Veh",
                field: "avgadsocc",
                mutator:intergerMutator,
                formatter:"money",
                formatterParams:{
                    thousand:",",
                    precision:2
                },
                bottomCalc:adsoccWeightAvgCalc,
                bottomCalcFormatter:"money",
                bottomCalcFormatterParams:{
                    thousand:",",
                    symbol:"Avg: ",
                    precision:2}
                    },
            {
                title: "New - Avg. Persons/Veh",
                field: "avggisocc",
                mutator:intergerMutator,
                formatter:"money",
                formatterParams:{precision:2},
                bottomCalc:gisoccWeightAvgCalc,
                bottomCalcFormatter:"money",
                bottomCalcFormatterParams:{
                    thousand:",",
                    symbol:"Avg: ",
                    precision:2
                }
                },
            {
                title: "Old - Avg. Wkly. Circ.",
                field: "avgadscirc"
                ,mutator:intergerMutator,
                formatter:"money",
                formatterParams:{
                    precision:0,
                },
                bottomCalc:adscircWeightAvgCalc,
                bottomCalcFormatter:"money",
                bottomCalcFormatterParams:{
                    thousand:",",
                    symbol:"Avg: ",
                    precision:0
                }
                },
            {
                title: "New - Avg. Wkly. Circ.",
                field: "avggiscirc",
                mutator:intergerMutator,
                formatter:"money",
                formatterParams:{
                    thousand:",",
                    precision:0
                },
                bottomCalc:giscircWeightAvgCalc,
                bottomCalcFormatter:"money",
                bottomCalcFormatterParams:{
                    thousand:",",
                    symbol:"Avg: ",
                    precision:0}
                    },
            {
                title: "Old - Avg. Wkly. Illum. Circ.",
                field: "avgadscircill",
                mutator:intergerMutator,
                formatter:"money",
                formatterParams:{
                    thousand:",",
                    precision:0
                },
                bottomCalc:adscircillWeightAvgCalc,
                bottomCalcFormatter:"money",
                bottomCalcFormatterParams:{
                    thousand:",",
                    symbol:"Avg: ",
                    precision:0
                }
                },
            {
                title: "New - Avg. Wkly Illum. Circ.",
                field: "avggiscircill",
                mutator:intergerMutator,
                formatter:"money",
                formatterParams:{
                    thousand:",",
                    precision:0
                },
                bottomCalc:giscircillWeightAvgCalc,
                bottomCalcFormatter:"money",
                bottomCalcFormatterParams:{
                    thousand:",",
                    symbol:"Avg: ",
                    precision:0
                }
                },
        ],
        dataLoaded: function (){
            this.getColumn("frame_media_name").reloadHeaderFilter();
            this.getColumn("account_name").reloadHeaderFilter();
            this.getColumn("dma_name").reloadHeaderFilter();

        },
        renderComplete:function(){
            operatorSumStatsTable2 = this.getData(true);
            createOperatorSumStatsCharts();
            // console.log('rendered')
        }
    });
    operatorSumStatsTabulator.setSort([
        {column:"dma_name", dir:"asc"}
    ])
}

//get avgs by market/media from sumstats info to display on tables
function getAvgsForImpCharts(avgSelectors) {
    let results1 = summaryStatsTable.filter(function (dma){
        return dma.dma_name === avgSelectors['dma_name'];
    });
    let results2 = results1.filter(function (media) {
        return media.frame_media_name === avgSelectors['frame_media_name'];
    });
    let avgidx = (parseFloat(results2[0]['gis5plusavg'])/parseFloat(results2[0]['ads5plusavg']))*100;
    impchart.ygrids.add(
        {value: avgidx, text: 'New Avg'}
    );
}
function getAvgsForFactorCharts(old_circ_ill,old_circ,old_speed,new_speed,new_circ_ill,new_circ,old_occ,new_occ,avgSelectors) {
    let results1 = summaryStatsTable.filter(function (dma){
        return dma.dma_name === avgSelectors['dma_name'];
    });
    let results2 = results1.filter(function (media) {
        return media.frame_media_name === avgSelectors['frame_media_name'];
    });
    let newspeedavgidx = (results2[0]['avggisspd']/results2[0]['avgadsspd'])*100;
    let newcircavgidx = (results2[0]['avggiscirc']/results2[0]['avgadscirc'])*100;
    let newoccavgidx = (results2[0]['avggisocc']/results2[0]['avgadsocc'])*100;
    let newcircillratioidx = ((results2[0]['avggiscircill']/results2[0]['avggiscirc'])/(results2[0]['avgadscircill']/results2[0]['avgadscirc']))*100;
    createSpeedChart(old_speed,new_speed,newspeedavgidx);
    createOccChart(old_occ,new_occ,newoccavgidx);
    createCircChart(old_circ,new_circ,newcircavgidx);
    createCircIllChart(old_circ_ill,new_circ_ill,old_circ,new_circ,newcircillratioidx);
}

//create individual panel charts for impressions and factors
function createImpCompChart(old_imps,new_imps,avgSelectors) {
    let oldimpidx = Math.round((old_imps/old_imps)*100);
    let newimpidx = Math.round((new_imps/old_imps)*100);
    impchart = c3.generate({
        bindto: '#imp-chart',
        size:{height:125},
        title: {
            text: 'Impressions Index (Persons 5+, Weekly)'
        },
        data: {
            columns: [
                ['impressions', oldimpidx,newimpidx],
            ],
            type: 'bar',
            labels: true,
            color: function(inColor, data) {
                let colors = ['#9a2c00', '#00839a'];
                if(data.index !== undefined) {
                    return colors[data.index];
                }
                return inColor;
            }
        },
        legend: {
            show: false
        },
        bar:{
            width: 25
        },
        axis: {
            rotated: true,
            x: {
                type: 'category',
                categories: ['Old Imp', 'New Imp'],

            },
            y: {
                tick: {
                    values: [25,50,75,100,125,150,175,200,225,250,275,300,325,350,375,400,425,450,475,500,750,1000,1500,2000,3000,4000,5000,10000],
                    format: function (d) {
                        return d +'%'
                    }
                }
            }
        },
    });
    getAvgsForImpCharts(avgSelectors)
}
function createCircChart(old_circ,new_circ,newcircavgidx) {
    let oldcircidx = Math.round((old_circ/old_circ)*100);
    let newcircidx = Math.round((new_circ/old_circ)*100);
    circchart = c3.generate({
        bindto: '#circ-chart',
        size:{height:125},
        title: {
            text: 'Total Circulation Index (Persons 5+, Weekly)'
        },
        data: {
            columns: [
                ['Circulation', oldcircidx,newcircidx],
            ],
            type: 'bar',
            labels: true,
            color: function(inColor, data) {
                let colors = ['#9a2c00', '#00839a'];
                if(data.index !== undefined) {
                    return colors[data.index];
                }
                return inColor;
            }
        },
        legend: {
            show: false
        },
        bar:{
            width: 25
        },
        axis: {
            rotated: true,
            x: {
                type: 'category',
                categories: ['Old Circ.', 'New Circ.'],

            },
            y: {
                tick: {
                    values: [25,50,75,100,125,150,175,200,225,250,275,300,325,350,375,400,425,450,475,500,750,1000,1500,2000,3000,4000,5000,10000],
                    format: function (d) {
                        return d +'%'
                    }
                }
            }
        },
        grid: {
            y: {
                lines: [
                    {value: newcircavgidx, text: 'New Avg'},
                ]
            }
        }
    });
}
function createCircIllChart(old_circ_ill,new_circ_ill,old_circ,new_circ,newcircillratioidx) {
    let oldillratio = Math.round(((old_circ_ill/old_circ)/(old_circ_ill/old_circ))*100);
    let newillratio = Math.round(((new_circ_ill/new_circ)/(old_circ_ill/old_circ))*100);
    circillchart = c3.generate({
        bindto: '#circIll-chart',
        size:{height:125},
        title: {
            text: 'Illumination Circulation Index (Weekly)'
        },
        data: {
            columns: [
                ['Illuminated Circulation', oldillratio,newillratio],
            ],
            type: 'bar',
            labels: true,
            color: function(inColor, data) {
                let colors = ['#9a2c00', '#00839a'];
                if(data.index !== undefined) {
                    return colors[data.index];
                }
                return inColor;
            }
        },
        legend: {
            show: false
        },
        bar:{
            width: 25
        },
        axis: {
            rotated: true,
            x: {
                type: 'category',
                categories: ['Old Circ. Ill.', 'New Circ. Ill.'],

            },
            y: {
                tick: {
                    values: [25,50,75,100,125,150,175,200,225,250,275,300,325,350,375,400,425,450,475,500,750,1000,1500,2000,3000,4000,5000,10000],
                    format: function (d) {
                        return d +'%'
                    }
                }
            }
        },
        grid: {
            y: {
                lines: [
                    {value: newcircillratioidx, text: 'New Avg'},
                ]
            }
        }
    });
}
function createOccChart(old_occ,new_occ,newoccavgidx) {
    let oldoccidx = Math.round((old_occ/old_occ)*100);
    let newoccidx = Math.round((new_occ/old_occ)*100);
    circchart = c3.generate({
        bindto: '#occ-chart',
        size:{height:125},
        title: {
            text: 'Persons per Vechicle Index'
        },
        data: {
            columns: [
                ['Occupancy', oldoccidx,newoccidx],
            ],
            type: 'bar',
            labels: true,
            color: function(inColor, data) {
                let colors = ['#9a2c00', '#00839a'];
                if(data.index !== undefined) {
                    return colors[data.index];
                }
                return inColor;
            }
        },
        legend: {
            show: false
        },
        bar:{
            width: 25
        },
        axis: {
            rotated: true,
            x: {
                type: 'category',
                categories: ['Old Occ.', 'New Occ.'],

            },
            y: {
                tick: {
                    values: [25,50,75,100,125,150,175,200,225,250,275,300,325,350,375,400,425,450,475,500,750,1000,1500,2000,3000,4000,5000,10000],
                    format: function (d) {
                        return d +'%'
                    }
                }
            }
        },
        grid: {
            y: {
                lines: [
                    {value: newoccavgidx, text: 'New Avg'},
                ]
            }
        }
    });
}
function createSpeedChart(old_speed,new_speed,newspeedavgidx) {
    let oldspdidx = Math.round((old_speed/old_speed)*100);
    let newspdidx = Math.round((new_speed/old_speed)*100);
    spdchart = c3.generate({
        bindto: '#speed-chart',
        title: {
            text: 'Average Speed Index'
        },
        size:{height:125},
        data: {
            columns: [
                ['speed', oldspdidx,newspdidx],
            ],
            type: 'bar',
            labels: true,
            color: function(inColor, data) {
                let colors = ['#9a2c00', '#00839a'];
                if(data.index !== undefined) {
                    return colors[data.index];
                }
                return inColor;
            }
        },
        legend: {
            show: false
        },
        bar:{
            width: 25
        },
        axis: {
            rotated: true,
            x: {
                type: 'category',
                categories: ['Old Spd', 'New Spd']
            },
            y: {
                tick: {
                    values: [25,50,75,100,125,150,175,200,225,250,275,300,325,350,375,400,425,450,475,500,750,1000,1500,2000,3000,4000,5000,10000],
                    format: function (d) {
                        return d +'%'
                    }
                }
            }
        },
        grid: {
            y: {
                lines: [
                    {value: newspeedavgidx, text: 'New Avg'},
                ]
            }
        }
    });
}

//create operator level charts for impressions and factors
function createOppImpCompChart(old_imps,new_imps) {
    let oldimpidx = Math.round((old_imps/old_imps)*100);
    let newimpidx = Math.round((new_imps/old_imps)*100);
    impchart = c3.generate({
        bindto: '#opsumstat-imp-chart',
        size:{height:125},
        title: {
            text: 'Impressions Index (Persons 5+, Weekly)'
        },
        data: {
            columns: [
                ['impressions', oldimpidx,newimpidx],
            ],
            type: 'bar',
            labels: true,
            color: function(inColor, data) {
                let colors = ['#9a2c00', '#00839a'];
                if(data.index !== undefined) {
                    return colors[data.index];
                }
                return inColor;
            }
        },
        legend: {
            show: false
        },
        bar:{
            width: 25
        },
        axis: {
            rotated: true,
            x: {
                type: 'category',
                categories: ['Old Imp', 'New Imp'],

            },
            y: {
                tick: {
                    values: [25,50,75,100,125,150,175,200,225,250,275,300,325,350,375,400,425,450,475,500,750,1000,1500,2000,3000,4000,5000,10000],
                    format: function (d) {
                        return d +'%'
                    }
                }
            }
        },
    });
}
function createOppCircChart(old_circ,new_circ) {
    let oldcircidx = Math.round((old_circ/old_circ)*100);
    let newcircidx = Math.round((new_circ/old_circ)*100);
    circchart = c3.generate({
        bindto: '#opsumstat-circ-chart',
        size:{height:125},
        title: {
            text: 'Total Circulation Index (Persons 5+, Weekly)'
        },
        data: {
            columns: [
                ['Circulation', oldcircidx,newcircidx],
            ],
            type: 'bar',
            labels: true,
            color: function(inColor, data) {
                let colors = ['#9a2c00', '#00839a'];
                if(data.index !== undefined) {
                    return colors[data.index];
                }
                return inColor;
            }
        },
        legend: {
            show: false
        },
        bar:{
            width: 25
        },
        axis: {
            rotated: true,
            x: {
                type: 'category',
                categories: ['Old Circ.', 'New Circ.'],

            },
            y: {
                tick: {
                    values: [25,50,75,100,125,150,175,200,225,250,275,300,325,350,375,400,425,450,475,500,750,1000,1500,2000,3000,4000,5000,10000],
                    format: function (d) {
                        return d +'%'
                    }
                }
            }
        }
    });
}
function createOppCircIllChart(old_circ_ill,new_circ_ill,old_circ,new_circ) {
    let oldillratio = Math.round(((old_circ_ill/old_circ)/(old_circ_ill/old_circ))*100);
    let newillratio = Math.round(((new_circ_ill/new_circ)/(old_circ_ill/old_circ))*100);
    circillchart = c3.generate({
        bindto: '#opsumstat-circIll-chart',
        size:{height:125},
        title: {
            text: 'Illumination Circulation Index (Weekly)'
        },
        data: {
            columns: [
                ['Illuminated Circulation', oldillratio,newillratio],
            ],
            type: 'bar',
            labels: true,
            color: function(inColor, data) {
                let colors = ['#9a2c00', '#00839a'];
                if(data.index !== undefined) {
                    return colors[data.index];
                }
                return inColor;
            }
        },
        legend: {
            show: false
        },
        bar:{
            width: 25
        },
        axis: {
            rotated: true,
            x: {
                type: 'category',
                categories: ['Old Circ. Ill.', 'New Circ. Ill.'],
            },
            y: {
                tick: {
                    values: [25,50,75,100,125,150,175,200,225,250,275,300,325,350,375,400,425,450,475,500,750,1000,1500,2000,3000,4000,5000,10000],
                    format: function (d) {
                        return d +'%'
                    }
                }
            }
        }
    });
}
function createOppOccChart(old_occ,new_occ) {
    let oldoccidx = Math.round((old_occ/old_occ)*100);
    let newoccidx = Math.round((new_occ/old_occ)*100);
    circchart = c3.generate({
        bindto: '#opsumstat-occ-chart',
        size:{height:125},
        title: {
            text: 'Persons per Vechicle Index'
        },
        data: {
            columns: [
                ['Occupancy', oldoccidx,newoccidx],
            ],
            type: 'bar',
            labels: true,
            color: function(inColor, data) {
                let colors = ['#9a2c00', '#00839a'];
                if(data.index !== undefined) {
                    return colors[data.index];
                }
                return inColor;
            }
        },
        legend: {
            show: false
        },
        bar:{
            width: 25
        },
        axis: {
            rotated: true,
            x: {
                type: 'category',
                categories: ['Old Occ.', 'New Occ.'],

            },
            y: {
                tick: {
                    values: [25,50,75,100,125,150,175,200,225,250,275,300,325,350,375,400,425,450,475,500,750,1000,1500,2000,3000,4000,5000,10000],
                    format: function (d) {
                        return d +'%'
                    }
                }
            }
        }
    });
}
function createOppSpeedChart(old_speed,new_speed) {
    let oldspdidx = Math.round((old_speed/old_speed)*100);
    let newspdidx = Math.round((new_speed/old_speed)*100);
    spdchart = c3.generate({
        bindto: '#opsumstat-speed-chart',
        title: {
            text: 'Average Speed Index'
        },
        size:{height:125},
        data: {
            columns: [
                ['speed', oldspdidx,newspdidx],
            ],
            type: 'bar',
            labels: true,
            color: function(inColor, data) {
                let colors = ['#9a2c00', '#00839a'];
                if(data.index !== undefined) {
                    return colors[data.index];
                }
                return inColor;
            }
        },
        legend: {
            show: false
        },
        bar:{
            width: 25
        },
        axis: {
            rotated: true,
            x: {
                type: 'category',
                categories: ['Old Spd', 'New Spd']
            },
            y: {
                tick: {
                    values: [25,50,75,100,125,150,175,200,225,250,275,300,325,350,375,400,425,450,475,500,750,1000,1500,2000,3000,4000,5000,10000],
                    format: function (d) {
                        return d +'%'
                    }
                }
            }
        }
    });
}
function createIssueChart(spotsumtotalcalc,unauditedtotalcalc,auditedLevel1totalcalc, auditedLevel9totalcalc, publishedMeasuredtotalcalc, publishedSuppresedtotalcalc, publishedUnderReviewtotalcalc, otherStatusestotalcalc) {
    issuechart = c3.generate({
        bindto: '#issueChart',
        title: {
            text: 'Audit Status'
        },
        size:{
            height:125,
            width: 700},
        data: {
            columns: [
                // ['No Issue', noIssueTotalcalc],
                ['Unaudited', unauditedtotalcalc],
                ['Audited Level 1', auditedLevel1totalcalc],
                ['Audited Level 9', auditedLevel9totalcalc],
                ['Published Under Review', publishedUnderReviewtotalcalc],
                ['Published Suppresed', publishedSuppresedtotalcalc],
                ['Other Statuses', otherStatusestotalcalc],
            ],
            type: 'bar',
            groups: [
                [
                    'Unaudited',
                    'Audited Level 1',
                    'Audited Level 9',
                    'Published Under Review',
                    'Published Suppresed',
                    'Other Statuses'
                ]
            ],
            labels: true,
        },
        legend: {
            show: true
        },
        bar:{
            width: 25
        },
        axis: {
            rotated: true,
        }
    });
}


//do the calculations and kickoff chart creation for operator level charts
function createOperatorSumStatsCharts() {
    let old_imps_array          = [];
    let new_imps_array          = [];
    let old_circ_array          = [];
    let new_circ_array          = [];
    let old_circ_ill_array      = [];
    let new_circ_ill_array      = [];
    let old_occ_array           = [];
    let new_occ_array           = [];
    let old_speed_array         = [];
    let new_speed_array         = [];
    let unit_array              = [];
    $.each(operatorSumStatsTable2, function (index,value) {
        old_imps_array .push(value.ads5plusavg);
        new_imps_array .push(value.gis5plusavg);
        old_circ_array .push(value.avgadscirc);
        new_circ_array .push(value.avggiscirc);
        old_circ_ill_array .push(value.avgadscircill);
        new_circ_ill_array .push(value.avggiscircill);
        old_occ_array .push(value.avgadsocc);
        new_occ_array .push(value.avggisocc);
        old_speed_array .push(value.avgadsspd);
        new_speed_array .push(value.avggisspd);
        unit_array      .push(value.panelcount)
    });
    let old_imps = weightedMean(old_imps_array,unit_array);
    let new_imps = weightedMean(new_imps_array,unit_array);
    let old_circ = weightedMean(old_circ_array,unit_array);
    let new_circ = weightedMean(new_circ_array,unit_array);
    let old_circ_ill = weightedMean(old_circ_ill_array,unit_array);
    let new_circ_ill = weightedMean(new_circ_ill_array,unit_array);
    let old_occ = weightedMean(old_occ_array,unit_array);
    let new_occ = weightedMean(new_occ_array,unit_array);
    let old_speed = weightedMean(old_speed_array,unit_array);
    let new_speed = weightedMean(new_speed_array,unit_array);
    createOppImpCompChart(old_imps,new_imps);
    createOppSpeedChart(old_speed,new_speed);
    createOppOccChart(old_occ,new_occ);
    createOppCircChart(old_circ,new_circ,);
    createOppCircIllChart(old_circ_ill,new_circ_ill,old_circ,new_circ)
}
function createIssueChartSums() {
    let unaudited = [];
    let auditedLevel1 = [];
    let auditedLevel9 = [];
    let publishedMeasured = [];
    let publishedUnderReview = [];
    let publishedSuppresed = [];
    let otherStatuses = [];
    let spotsumtotal = [];
    $.each(issueStats, function (index,value) {
        spotsumtotal.push(parseInt(value.spot_count));
        if (value.status_type_name ==='Unaudited'){
            unaudited.push(parseInt(value.spot_count))
        }
        else if (value.status_type_name === 'Audited - Level 1'){
            auditedLevel1.push(parseInt(value.spot_count))
        }
        else if (value.status_type_name === 'Audited - Level 9'){
            auditedLevel9.push(parseInt(value.spot_count))
        }
        else if (value.status_type_name === 'Published - Measured'){
            publishedMeasured.push(parseInt(value.spot_count))
        }
        else if (value.status_type_name === 'Published - Under Review'){
            publishedUnderReview.push(parseInt(value.spot_count))
        }
        else if (value.status_type_name === 'Published - Suppressed'){
            publishedSuppresed.push(parseInt(value.spot_count))
        }
        else {
            // console.log(value.status_type_name)
            otherStatuses.push(parseInt(value.spot_count))
        }
    });
    let spotsumtotalcalc = arrSum(spotsumtotal);
    let unauditedtotalcalc = arrSum(unaudited);
    let auditedLevel1totalcalc = arrSum(auditedLevel1);
    let auditedLevel9totalcalc = arrSum(auditedLevel9);
    let publishedMeasuredtotalcalc = arrSum(publishedMeasured);
    let publishedUnderReviewtotalcalc = arrSum(publishedUnderReview);
    let publishedSuppresedtotalcalc = arrSum(publishedSuppresed);
    let otherStatusestotalcalc = arrSum(otherStatuses);
    createIssueChart(spotsumtotalcalc,unauditedtotalcalc,auditedLevel1totalcalc, auditedLevel9totalcalc, publishedMeasuredtotalcalc, publishedSuppresedtotalcalc, publishedUnderReviewtotalcalc, otherStatusestotalcalc)
}

//define list of sheets from tables and download xlsx file

$(function () {

    $("#btn-marketdownload").click(exportMarketStats);
    $("#btn-inventorydownload").click(exportOpInv);
    $("#btn-issuedownload").click(exportOpIssue);
    createOverallSumChart()
});

//create overall sum chart in the modal
function createOverallSumChart (){
    let sumChart = c3.generate({
        bindto: '#overall_sum_chart',
        size:{height:200,
            width: 700},
        title: {
            text: 'Overal Spot and Review Summary'
        },
        data: {
            x: 'x',
            columns: [
                ['x', '2019-04-02', '2019-04-27','2019-05-17', '2019-06-04', '2019-07-08','2019-08-05','2019-09-05','2019-09-13'],
                ['totalSpots', 482040, 486691, 488468, 488468, 492436, 492833,493303,493303],
                ['underReview', 233752, 175302, 185138, 152208, 140737, 116752, 58142,53899]
            ],
            types: {
                totalSpots: 'area',
                underReview: 'area-spline'
            },
            colors: {
                totalSpots: '#00839a',
                underReview: '#9a2c00'
            }
        },
        axis: {
            x: {
                type: 'timeseries',
                tick: {
                    format: '%Y-%m-%d'
                }

            },
        }
    });
}










